// NavBar.js

import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const [user, setUser] = useState(null); // State to hold the authenticated user
  const navigate = useNavigate();

  // Function to fetch the current user
  const fetchUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  };

  // Fetch user on component mount and listen to auth state changes
  useEffect(() => {
    fetchUser();

    // Listen to authentication state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setUser(session?.user || null);
      }
    );

    // Cleanup the listener on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setUser(null); // Update user state
      navigate('/'); // Redirect to home page or wherever appropriate
    }
  };

  return (
    <nav className="flex items-center justify-between py-4 bg-white shadow-md">
      {/* Logo */}
      <div className="ml-6">
        <a href="/">
          <img
            src="/logo.png"
            alt="Logo"
            className="h-8"
          />
        </a>
      </div>

      {/* Sign Out Button */}
      {user && (
        <div className="mr-6">
          <button
            onClick={handleLogout}
            className="px-4 py-2 text-white bg-blue-500 rounded-md"
          >
            Sign Out
          </button>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
