// Header.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null); // State to hold the authenticated user
  const navigate = useNavigate();

  // Function to fetch the current user
  const fetchUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  };

  // Fetch user on component mount
  useEffect(() => {
    fetchUser();

    // Listen to authentication state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setUser(session?.user || null);
      }
    );

    // Cleanup the listener on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setUser(null); // Update the user state to null
      navigate('/');
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const LogoutButton = () => (
    <button
      onClick={handleLogout}
      className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
    >
      Sign Out
    </button>
  );

  return (
    <header className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between w-full px-6 py-4 bg-black shadow-md">
      {/* Logo */}
      <img
        src="https://cdn.prod.website-files.com/65c5332d5eb951bbf0fe2e26/66513aa69f85c121a57439da_DollyVarden-p-500.png"
        alt="Company Logo"
        className="h-6 md:h-8"
      />

      {/* Hamburger Menu for mobile */}
      <div className="flex items-center md:hidden">
        {user && (
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            {mobileMenuOpen ? (
              <XMarkIcon className="w-6 h-6" />
            ) : (
              <Bars3Icon className="w-6 h-6" />
            )}
          </button>
        )}
      </div>

      {/* Desktop Account Button */}
      {user && (
        <div className="relative items-center hidden md:flex">
          <button
            onClick={toggleDropdown}
            className="px-4 py-2 text-white bg-gray-800 rounded-lg focus:outline-none"
          >
            Account
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 z-20 w-48 mt-2 bg-white rounded-md shadow-lg">
              <LogoutButton />
            </div>
          )}
        </div>
      )}

      {/* Mobile Menu Dropdown */}
      {mobileMenuOpen && user && (
        <div className="absolute right-0 z-20 w-48 mt-2 bg-white rounded-md shadow-lg top-14 md:hidden">
          <LogoutButton />
        </div>
      )}
    </header>
  );
};

export default Header;
