import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext } from '../InfoComponents/AuthProvider';
import NavBar from '../NavBar';

const SignupPage = () => {
  const [authStep, setAuthStep] = useState('email'); // Start with email step
  const [selectedBrokerage, setSelectedBrokerage] = useState(null);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [authError, setAuthError] = useState(null);
  const [loginLink, setLoginLink] = useState(null);
  const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const brokerageOptions = [
    { value: 'ETRADE', label: 'E*TRADE' },
    { value: 'ROBINHOOD', label: 'Robinhood' },
    { value: 'SCHWAB', label: 'Schwab' },
    { value: 'TRADESTATION', label: 'TradeStation' },
    { value: 'VANGUARD', label: 'Vanguard US' },
    { value: 'WEALTHSIMPLETRADE', label: 'Wealthsimple' },
    { value: 'WEBULL', label: 'Webull US' },
  ];

  // Handle email submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);
    setIsSendCodeDisabled(true);

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          channel: 'email', // Send OTP code
          shouldCreateUser: true,
        },
      });

      if (error) {
        setAuthError(error.message);
        setIsSendCodeDisabled(false);
      } else {
        setAuthStep('otp');
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
      setIsSendCodeDisabled(false);
    }
  };

  // Handle OTP verification
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);

    try {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email', // Verify OTP code
      });

      if (error) {
        setAuthError(error.message);
      } else {
        // Authentication successful
        setAuthStep('selectBrokerage'); // Proceed to brokerage selection
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
    }
  };

  // Handle brokerage selection
  const handleBrokerageSelect = (selectedOption) => {
    setSelectedBrokerage(selectedOption);
    setAuthError(null); // Clear any previous errors
    setLoginLink(null); // Reset the login link
    setAuthStep('linking'); // Proceed to linking
  };

  // Effect to handle post-authentication steps
  useEffect(() => {
    const postAuthProcess = async () => {
      if (user) {
        localStorage.setItem('supabaseUserId', user.id);

        // Check if user has accounts linked
        const isLinked = await checkIfUserIsLinked(user.id);

        if (isLinked) {
          // User has accounts linked, proceed
          navigate('/'); // Redirect to dashboard or desired page
        } else if (selectedBrokerage) {
          // User has selected brokerage, proceed to linking
          setAuthStep('linking');
        } else {
          // User does not have selected brokerage, proceed to select brokerage
          setAuthStep('selectBrokerage');
        }
      }
    };

    postAuthProcess();
  }, [user, selectedBrokerage, navigate]);

  // Check if user is linked
  const checkIfUserIsLinked = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await fetch(`${backendUrl}/is-linked`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Is user linked:', data.isLinked);
        return data.isLinked; // Expects { isLinked: true/false }
      } else {
        console.error('Failed to check if user is linked');
        return false;
      }
    } catch (error) {
      console.error('Error during checkIfUserIsLinked:', error);
      return false;
    }
  };

  // Fetch SnapTrade login link
  const fetchLoginLink = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await fetch(`${backendUrl}/link-snaptrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
        body: JSON.stringify({
          snaptradeUserId: `snaptrade-${supabaseUserId}`,
          brokerage: selectedBrokerage.value,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Received login link:', data.redirectURI);
        setLoginLink(data.redirectURI);
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || 'Failed to get SnapTrade login link'
        );
      }
    } catch (error) {
      console.error('Error during fetching login link:', error);
      setAuthError(error.message);
      setAuthStep('error');
    }
  };

  // Proceed to linking when authStep is 'linking', user and selectedBrokerage are available
  useEffect(() => {
    const proceedToLinking = async () => {
      if (authStep === 'linking' && user && selectedBrokerage && !loginLink) {
        await fetchLoginLink(user.id);
      }
    };

    proceedToLinking();
  }, [authStep, user, selectedBrokerage, loginLink]);

  // Handle messages from SnapTrade iframe
  useEffect(() => {
    if (authStep === 'linking' && user && selectedBrokerage) {
      const handleMessageEvent = async (e) => {
        if (e.origin !== 'https://app.snaptrade.com') {
          return;
        }

        if (e.data) {
          let data = e.data;

          if (typeof data === 'string') {
            if (data.startsWith('SUCCESS')) {
              console.log('SnapTrade linking successful');

              // Re-check if user has accounts linked
              const isLinked = await checkIfUserIsLinked(user.id);

              if (isLinked) {
                // Record giveaway entry
                await enterGiveaway(user.id);

                // Redirect to dashboard or desired page
                navigate('/dashboard'); // Adjust the path as needed
              } else {
                setAuthError(
                  'Please complete linking your brokerage accounts.'
                );
              }
            } else if (
              data === 'CLOSE_MODAL' ||
              data === 'ABANDONED' ||
              data === 'CLOSED'
            ) {
              console.log('User closed the SnapTrade linking modal');
              setAuthError('SnapTrade linking was cancelled.');
              setAuthStep('selectBrokerage');
              setLoginLink(null); // Reset the login link
            } else if (data.startsWith('ERROR')) {
              console.error('Error during SnapTrade linking:', data);
              setAuthError(`Error: ${data}`);
            }
          }
        }
      };

      window.addEventListener('message', handleMessageEvent, false);

      return () => {
        window.removeEventListener('message', handleMessageEvent, false);
      };
    }
  }, [authStep, user, selectedBrokerage, navigate]);

  // Function to enter a giveaway
  const enterGiveaway = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await fetch(`${backendUrl}/enter-giveaway`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Giveaway entry:', data.message);
      } else {
        const errorData = await response.text();
        console.error('Failed to enter giveaway:', errorData);
      }
    } catch (error) {
      console.error('Error entering giveaway:', error.message);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="flex flex-col h-screen md:flex-row signup-container">
        <div className="flex items-center justify-center flex-1 p-4 text-white bg-black md:items-start md:justify-start md:p-12">
          {/* Optional: Add branding or images */}
          <div className="text-center md:text-left">
            <h1 className="mb-4 text-2xl font-bold md:text-4xl">Create your account</h1>
            <p className="text-base md:text-lg">
              You'll use this to access the AI trading alerts system.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center flex-1 p-4 right-pane md:p-0">
          <div className="w-full max-w-md p-6">
            <div className="mb-4 text-center md:text-left">
              <h2 className="text-xl font-semibold md:text-2xl">
                {authStep === 'email' && 'Verify Email to Continue'}
                {authStep === 'otp' && 'Enter Verification Code'}
                {authStep === 'selectBrokerage' && 'Select a Platform'}
                {authStep === 'linking' && 'Sign In to Your Brokerage'}
              </h2>
            </div>
            {authError && (
              <div className="mb-4 text-center text-red-500 md:text-left">{authError}</div>
            )}
            {authStep === 'email' && (
              <form onSubmit={handleEmailSubmit} className="text-center md:text-left">
                <label className="block mb-2 font-bold">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isSendCodeDisabled}
                  className="w-full p-2 mb-4 border border-black rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className={`w-full py-2 text-white rounded-md ${
                    isSendCodeDisabled
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-black hover:bg-blue-400 hover:text-black hover:border-black'
                  }`}
                  disabled={isSendCodeDisabled}
                >
                  {isSendCodeDisabled ? 'Code Sent' : 'Send Code'}
                </button>
              </form>
            )}
            {authStep === 'otp' && (
              <form onSubmit={handleOtpSubmit} className="text-center md:text-left">
                <label className="block mb-2 font-bold">Verification Code</label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  className="w-full p-2 mb-4 border border-black rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="w-full py-2 text-white bg-black rounded-md hover:bg-blue-300"
                >
                  Verify Code
                </button>
              </form>
            )}
            {authStep === 'selectBrokerage' && (
              <div className="text-center md:text-left">
                <p className="mb-4">Please select your brokerage to continue:</p>
                <Select
                  options={brokerageOptions}
                  onChange={handleBrokerageSelect}
                  placeholder="Select your brokerage..."
                  isSearchable
                />
              </div>
            )}
            {authStep === 'linking' && (
              <div className="text-center md:text-left">
                {loginLink ? (
                  <iframe
                    id="snaptrade-connection-portal"
                    src={loginLink}
                    title="SnapTrade Connection Portal"
                    className="w-full h-96 md:h-[600px]"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <p>Loading trading account linking...</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;