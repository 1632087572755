import React, { useState, useEffect } from 'react';
import { SymbolOverview } from 'react-tradingview-embed';
import axios from 'axios';
import NavBar from '../NavBar';
import AuthModal from '../InfoComponents/AuthModal';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import BuyCard from '../InfoComponents/BuyCard';
import Footer from '../Footer';

const CompanyInfo = () => {
  // Initialize state variables
  const [company, setCompany] = useState(null);
  const [shares, setShares] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [stats, setStats] = useState([]);
  const [error, setError] = useState(null);
  const [marketPrice, setMarketPrice] = useState('0.00');
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const navigate = useNavigate();

  // Initialize Supabase client
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
  const supabase = createClient(supabaseUrl, supabaseAnonKey);

  // Get company ID and TradingView symbol from environment variables
  const companyId = parseInt(process.env.REACT_APP_COMPANY_ID, 10);
  const tradingViewSymbol = process.env.REACT_APP_TRADINGVIEW_SYMBOL;

  const handleSharesChange = (e) => {
    setShares(e.target.value);
  };

  const estimatedCost = (shares * parseFloat(marketPrice)).toFixed(2);

  // Function to open the video modal
  const openVideoModal = (video) => {
    setCurrentVideo(video);
    setShowModal('video');
  };

  // Function to open the news modal
  const openNewsModal = (newsItem) => {
    setCurrentNews(newsItem);
    setShowModal('news');
  };

  // Function to close the modal
  const closeModal = () => {
    setCurrentVideo(null);
    setCurrentNews(null);
    setShowModal(false);
  };

  // Function to handle "Next" button click
  const handleNextClick = () => {
    setIsAuthModalOpen(true);
  };

  // Fetch company data from Supabase
  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!companyId) {
        console.error(
          'Company ID is missing. Please set REACT_APP_COMPANY_ID in your environment variables.'
        );
        setError('Company ID is missing.');
        return;
      }
  
      console.log('Company ID:', companyId);
  
      try {
        const { data: companyData, error } = await supabase
          .from('companies')
          .select(`
            *,
            videos:company_videos(*),
            news_releases:company_news_releases(*)
          `)
          .eq('id', companyId)
          .maybeSingle(); // Use maybeSingle() to handle zero results
  
        if (error) {
          console.error('Error fetching company data:', error);
          setError('Failed to fetch company data.');
        } else if (!companyData) {
          console.error('No company data found for the given ID:', companyId);
          setError('No company data found.');
        } else {
          setCompany(companyData);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
        setError('Failed to fetch company data.');
      }
    };
  
    fetchCompanyData();
  }, [companyId]);
  

  // Fetch key statistics using useEffect
  useEffect(() => {
    const fetchStats = async () => {
      if (!company) return;

      const ALPHA_VANTAGE_API_KEY =
        process.env.REACT_APP_ALPHA_VANTAGE_API_KEY;
      const symbol = company.ticker_symbol_ca; // Use the company's ticker symbol

      if (!ALPHA_VANTAGE_API_KEY) {
        console.error(
          'API key is missing. Please set REACT_APP_ALPHA_VANTAGE_API_KEY in your .env file.'
        );
        setError('API key is missing.');
        return;
      }

      try {
        // Fetch global quote data from Alpha Vantage
        const response = await axios.get(
          'https://www.alphavantage.co/query',
          {
            params: {
              function: 'GLOBAL_QUOTE',
              symbol: symbol,
              apikey: ALPHA_VANTAGE_API_KEY,
            },
          }
        );

        const data = response.data['Global Quote'];

        if (data && data['05. price']) {
          // Format market price to two decimal places
          setMarketPrice(parseFloat(data['05. price']).toFixed(2));

          // Format and map data to stats array
          const fetchedStats = [
            {
              title: 'Open Price',
              value: data['02. open']
                ? `$${parseFloat(data['02. open']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'High Price',
              value: data['03. high']
                ? `$${parseFloat(data['03. high']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Low Price',
              value: data['04. low']
                ? `$${parseFloat(data['04. low']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Previous Close',
              value: data['08. previous close']
                ? `$${parseFloat(data['08. previous close']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Change',
              value: data['09. change']
                ? parseFloat(data['09. change']).toFixed(2)
                : 'N/A',
            },
            {
              title: 'Percent Change',
              value: data['10. change percent']
                ? `${parseFloat(
                    data['10. change percent'].replace('%', '')
                  ).toFixed(2)}%`
                : 'N/A',
            },
          ];

          setStats(fetchedStats);
        } else {
          console.error('No data available for the provided symbol.');
          setError('No data available for the provided symbol.');
        }
      } catch (error) {
        console.error('Error fetching key statistics:', error);
        setError('Failed to fetch key statistics.');
      }
    };

    fetchStats();
  }, [company]);

  // Handle loading and error states
  if (error) {
    return <div className="container p-6 mx-auto">Error: {error}</div>;
  }

  if (!company) {
    return <div className="container p-6 mx-auto">Loading...</div>;
  }

  return (
    <div>
      <NavBar />
      <div className="container p-6 mx-auto">
        {/* Main Content and Buy Card Grid */}
        <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-3">
          {/* Main Content */}
          <div className="md:col-span-2">
            {/* Chart */}
            <div className="my-6">
              <div className="rounded-md h-96">
                <SymbolOverview
                  widgetProps={{
                    colorTheme: 'light',
                    symbols: [[`${company.trading_view_symbol}|1Y`]],
                    showVolume: true,
                    backgroundColor: 'rgba(19, 23, 34, 0)',
                    widgetFontColor: 'rgba(19, 23, 43, 1)',
                  }}
                />
              </div>
            </div>
            {/* Buy Card (Mobile) */}
            <div className="block md:hidden">
              <BuyCard
                company={company}
                shares={shares}
                handleSharesChange={handleSharesChange}
                marketPrice={marketPrice}
                estimatedCost={estimatedCost}
                handleNextClick={handleNextClick}
              />
            </div>

            {/* About Company */}
            <div>
              <h2 className="mb-2 text-lg font-semibold">About {company.name}</h2>
              <p className="mb-4 text-gray-700">{company.description}</p>

              {/* Company Key Statistics */}
              <h3 className="mb-2 text-lg font-semibold">
                {company.ticker_symbol_ca} Key Statistics
              </h3>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                {stats.length > 0 ? (
                  stats.map((stat, index) => (
                    <div key={index} className="text-left">
                      <p className="font-bold">{stat.title}</p>
                      <p className="text-gray-700">{stat.value}</p>
                    </div>
                  ))
                ) : (
                  <p>Loading key statistics...</p>
                )}
              </div>

              {/* Buy Card (Mobile) */}
              <div className="block md:hidden">
                <BuyCard
                  company={company}
                  shares={shares}
                  handleSharesChange={handleSharesChange}
                  marketPrice={marketPrice}
                  estimatedCost={estimatedCost}
                  handleNextClick={handleNextClick}
                />
              </div>

              {/* Company Presentation */}
              {/* Adjust or remove this section if needed */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">
                  Company Presentation
                </h3>
                <div className="mt-4">
                  <iframe
                    src={company.presentation}
                    title="Vrify Deck"
                    width="100%"
                    height="600px"
                    allowFullScreen
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>
              
              {/* Buy Card (Mobile) */}
              <div className="block md:hidden">
                <BuyCard
                  company={company}
                  shares={shares}
                  handleSharesChange={handleSharesChange}
                  marketPrice={marketPrice}
                  estimatedCost={estimatedCost}
                  handleNextClick={handleNextClick}
                />
              </div>

              {/* Company Media */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">{company.ticker_symbol_ca} Media</h3>
                <div className="flex flex-wrap gap-4 mt-4">
                  {company.videos && company.videos.length > 0 ? (
                    company.videos.map((video, i) => (
                      <div
                        key={i}
                        className="relative w-full md:w-[48%] h-64 bg-gray-200 rounded-md cursor-pointer overflow-hidden"
                        onClick={() => openVideoModal(video)}
                      >
                        <img
                          src={video.thumbnail_url}
                          alt={video.title}
                          className="object-cover w-full h-full"
                        />
                        {/* Dark overlay */}
                        <div className="absolute inset-0 bg-black opacity-25"></div>
                        {/* Play Icon */}
                        <div className="absolute inset-0 flex items-center justify-center">
                          <svg
                            className="w-16 h-16 text-white opacity-75"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M6 4l12 6-12 6V4z" />
                          </svg>
                        </div>
                        {/* Video Title */}
                        <div className="absolute bottom-0 w-full p-2 text-sm text-white bg-black bg-opacity-50">
                          {video.title}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No videos available.</p>
                  )}
                </div>
              </div>

              {/* Buy Card (Mobile) */}
              <div className="block md:hidden">
                <BuyCard
                  company={company}
                  shares={shares}
                  handleSharesChange={handleSharesChange}
                  marketPrice={marketPrice}
                  estimatedCost={estimatedCost}
                  handleNextClick={handleNextClick}
                />
              </div>

              {/* Company News */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">{company.ticker_symbol_ca} News</h3>
                <ul className="mt-4 space-y-4 text-gray-700">
                  {company.news_releases && company.news_releases.length > 0 ? (
                    [...company.news_releases]
                      .sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date)) // Sort by pub_date in descending order
                      .map((newsItem, i) => (
                        <li
                          key={i}
                          className="cursor-pointer"
                          onClick={() => openNewsModal(newsItem)}
                        >
                          <p>{new Date(newsItem.pub_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                          <p className="font-semibold">{newsItem.title}</p>
                        </li>
                      ))
                  ) : (
                    <p>No news available.</p>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {/* Buy Card (Mobile) */}
          <div className="block md:hidden">
                <BuyCard
                  company={company}
                  shares={shares}
                  handleSharesChange={handleSharesChange}
                  marketPrice={marketPrice}
                  estimatedCost={estimatedCost}
                  handleNextClick={handleNextClick}
                />
              </div>


            {/* Buy Card (Sidebar for md and up) */}
            <div className="sticky hidden top-20 h-max md:block">
              <BuyCard
                company={company}
                shares={shares}
                handleSharesChange={handleSharesChange}
                marketPrice={marketPrice}
                estimatedCost={estimatedCost}
                handleNextClick={handleNextClick}
              />
            </div>
          </div>
          {/* Buy Card
          <div className="sticky p-4 border border-blue-500 rounded-md top-20 h-max">
            <h2 className="mb-4 text-lg font-semibold">Buy {company.ticker_symbol_ca}</h2>
            <div className="flex items-center justify-between mb-4">
              <label className="font-bold">Shares</label>
              <input
                type="number"
                value={shares}
                onChange={handleSharesChange}
                placeholder="Shares"
                className="w-24 p-2 border rounded-md"
              />
            </div>
            <div className="flex items-center justify-between mb-2">
              <p className="font-bold">Market Price</p>
              <p>${marketPrice}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold">Estimated Cost</p>
              <p>${estimatedCost}</p>
            </div>
            <button
              className="w-full py-2 mt-4 text-white bg-blue-500 rounded-md"
              onClick={handleNextClick}
            >
              Select Brokerage
            </button>
          </div>
        </div> */}

        {/* Video Modal */}
        {showModal === 'video' && currentVideo && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-5xl p-4 bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">
                  {currentVideo.title}
                </h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div className="relative" style={{ paddingTop: '56.25%' }}>
                <iframe
                  src={`${currentVideo.url}?autoplay=1`}
                  title={currentVideo.title}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        {/* News Modal */}
        {showModal === 'news' && currentNews && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-3xl max-h-full p-4 overflow-y-auto bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">
                  {currentNews.title}
                </h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div>
              <p>{new Date(currentNews.pub_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                <div className="mt-4">
                  {/* Embed the news page using an iframe */}
                  <iframe
                    src={currentNews.url}
                    title={currentNews.title}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* AuthModal Component */}
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
          onAuthSuccess={(userId, shares) => {
            // Handle successful authentication and SnapTrade linking
            console.log('Auth success:', userId);
            setIsAuthModalOpen(false);
            // Redirect to post-authorization page with shares value
            navigate('/post-authorization', { state: { shares } });
          }}
          shares={shares} // Pass shares as a prop
        />
      </div>
      <Footer />
    </div>
  );
};

export default CompanyInfo;
