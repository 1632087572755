// src/components/LoadingOverlay.js

import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="flex flex-col items-center">
        {/* Choose one of the following animations */}

        {/* Option 1: Circular Loading Animation */}
        {/* <div className="relative w-24 h-24 mb-4">
          <div className="absolute inset-0">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-gray-300"
                cx="50"
                cy="50"
                r="45"
                stroke="currentColor"
                strokeWidth="10"
                fill="none"
              />
              <circle
                className="text-blue-500 animate-[spin_2s_linear_infinite]"
                cx="50"
                cy="50"
                r="45"
                stroke="currentColor"
                strokeWidth="10"
                strokeDasharray="283"
                strokeDashoffset="75"
                strokeLinecap="round"
                fill="none"
                transform="rotate(-90 50 50)"
              />
            </svg>
          </div>
        </div> */}

        {/* Option 2: Pulsing Dot Animation */}
        {/* Uncomment this section if you prefer the pulsing dot */}
        
        <div className="relative flex items-center justify-center w-16 h-16 mb-4">
          <div className="w-8 h-8 bg-blue-500 rounded-full animate-ping"></div>
        </div>
       

        {/* Loading Text */}
        <div className="text-lg text-white">Loading...</div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
