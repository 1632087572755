// BuyCard.js

import React from 'react';

const BuyCard = ({
  company,
  shares,
  handleSharesChange,
  marketPrice,
  estimatedCost,
  handleNextClick,
}) => (
  <div className="p-4 border-blue-500 rounded-md shadow-md">
    <h2 className="mb-4 text-lg font-semibold">
      Buy {company.ticker_symbol_ca}
    </h2>
    <div className="flex items-center justify-between mb-4">
      <label className="font-bold">Shares</label>
      <input
        type="number"
        value={shares}
        onChange={handleSharesChange}
        placeholder="Shares"
        className="w-24 p-2 border rounded-md"
      />
    </div>
    <div className="flex items-center justify-between mb-2">
      <p className="font-bold">Market Price</p>
      <p>${marketPrice}</p>
    </div>
    <div className="flex items-center justify-between">
      <p className="font-bold">Estimated Cost</p>
      <p>${estimatedCost}</p>
    </div>
    <button
      className="w-full py-2 mt-4 text-white bg-blue-500 rounded-md"
      onClick={handleNextClick}
    >
      Supported Trading Platforms
    </button>
  </div>
);

export default BuyCard;
