import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import LinkAccountButton from './LinkAccountButton';
import Header from './Header';
import Footer from './Footer'; // Import the Footer component

const EnterGiveaway = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [supabaseUserId, setSupabaseUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('EnterGiveaway component rendered');

    const fetchUser = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
          console.error('Error fetching user:', error.message);
          setError('Failed to fetch user information. Please refresh or login again.');
          return;
        }
        if (user) {
          console.log('User fetched');
          // console.log('Supabase User:', user);
          setSupabaseUserId(user.id);
        } else {
          setError('User is not logged in.');
        }
      } catch (fetchError) {
        // console.error('Error fetching user:', fetchError);
        setError('Error fetching user information.');
      }
    };

    fetchUser();
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between bg-black text-white p-6">
      <Header /> {/* Use the Header component */}

      {/* Main Content */}
      <div className="flex flex-1 items-center justify-center"> {/* Flexbox for centering */}
        <div className="flex flex-col lg:flex-row items-center justify-center bg-black text-white rounded-lg shadow-lg max-w-6xl w-full p-8 space-y-8 lg:space-y-0 lg:space-x-12">
          {/* YouTube Video Section */}
          <div className="w-full lg:w-3/4"> {/* Increased width to make the video wider */}
            <iframe
              width="100%"
              height="450" // Keep the height increased
              src="https://www.youtube.com/embed/3oaY-bJ-dRg?autoplay=1&mute=0&si=8d6-aJLSmuXOFK0v" // Added autoplay and mute for user experience
              title="Dolly Varden Silver"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full rounded-md shadow-lg"
            ></iframe>
          </div>

          {/* Text Section */}
          <div className="w-full lg:w-1/3 text-center flex flex-col items-center lg:items-start justify-center space-y-4">
            <h2 className="text-4xl font-semibold text-white mb-4">You Have 1 Entry!</h2>
            <p className="text-lg text-white-300 text-center lg:text-left">
              You have successfully signed up and entered into the giveaway! For a chance to win a 10 ounce certified Silver bar. 🎉
            </p>

            {/* Link Account Button */}
            {supabaseUserId && (
              <div className="mt-6 flex justify-center w-full">
                <LinkAccountButton supabaseUserId={supabaseUserId} />
              </div>
            )}
            {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
          </div>
        </div>
      </div>

      <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default EnterGiveaway;
