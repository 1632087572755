import React, { useState, useEffect } from 'react';
import { SymbolOverview } from 'react-tradingview-embed';
import axios from 'axios';
import NavBar from '../NavBar';
import LoadingOverlay from '../LoadingOverlay';
import { useNavigate, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import BuyCardPostAuth from '../InfoComponents/BuyCardPostAuth';
import Footer from '../Footer';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PostAuthorization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shares, setShares] = useState(location.state?.shares || '');
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [stats, setStats] = useState([]);
  const [error, setError] = useState(null);
  const [marketPrice, setMarketPrice] = useState('0.00');
  const [company, setCompany] = useState(null);

  // Add these state variables
  const [universalSymbolId, setUniversalSymbolId] = useState(null);
  const [symbolData, setSymbolData] = useState(null);


  const [accounts, setAccounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderType: 'Market',
    quantity: 1,
    limitPrice: '',
  });
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userSecret, setUserSecret] = useState(null);
  const [disableInteractions, setDisableInteractions] = useState(false);

  // New state variables for order confirmation
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderData, setOrderData] = useState(null);

  // Initialize Supabase client
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
  const supabase = createClient(supabaseUrl, supabaseAnonKey);

  // Get company ID and TradingView symbol from environment variables
  const companyId = process.env.REACT_APP_COMPANY_ID;
  const tradingViewSymbol = process.env.REACT_APP_TRADINGVIEW_SYMBOL;

  // Fetch company data from Supabase
  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!companyId) {
        console.error(
          'Company ID is missing. Please set REACT_APP_COMPANY_ID in your environment variables.'
        );
        setError('Company ID is missing.');
        return;
      }

      try {
        const { data: companyData, error } = await supabase
          .from('companies')
          .select(
            `
            *,
            videos:company_videos(*),
            news_releases:company_news_releases(*)
          `
          )
          .eq('id', companyId)
          .single();

        if (error) {
          console.error('Error fetching company data:', error);
          setError('Failed to fetch company data.');
        } else {
          setCompany(companyData);
          setCompanyName(companyData.name);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
        setError('Failed to fetch company data.');
      }
    };

    fetchCompanyData();
  }, [companyId]);

  // Fetch key statistics using useEffect
  useEffect(() => {
    const fetchStats = async () => {
      if (!company) return;

      const ALPHA_VANTAGE_API_KEY =
        process.env.REACT_APP_ALPHA_VANTAGE_API_KEY;
      const symbol = company.ticker_symbol_ca; // Use the company's ticker symbol

      if (!ALPHA_VANTAGE_API_KEY) {
        console.error(
          'API key is missing. Please set REACT_APP_ALPHA_VANTAGE_API_KEY in your .env file.'
        );
        setError('API key is missing.');
        return;
      }

      try {
        // Fetch global quote data from Alpha Vantage
        const response = await axios.get('https://www.alphavantage.co/query', {
          params: {
            function: 'GLOBAL_QUOTE',
            symbol: symbol,
            apikey: ALPHA_VANTAGE_API_KEY,
          },
        });

        const data = response.data['Global Quote'];

        if (data && data['05. price']) {
          // Format market price to two decimal places
          setMarketPrice(parseFloat(data['05. price']).toFixed(2));

          // Format and map data to stats array
          const fetchedStats = [
            {
              title: 'Open Price',
              value: data['02. open']
                ? `$${parseFloat(data['02. open']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'High Price',
              value: data['03. high']
                ? `$${parseFloat(data['03. high']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Low Price',
              value: data['04. low']
                ? `$${parseFloat(data['04. low']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Previous Close',
              value: data['08. previous close']
                ? `$${parseFloat(data['08. previous close']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Change',
              value: data['09. change']
                ? parseFloat(data['09. change']).toFixed(2)
                : 'N/A',
            },
            {
              title: 'Percent Change',
              value: data['10. change percent']
                ? `${parseFloat(
                    data['10. change percent'].replace('%', '')
                  ).toFixed(2)}%`
                : 'N/A',
            },
          ];

          setStats(fetchedStats);
        } else {
          console.error('No data available for the provided symbol.');
          setError('No data available for the provided symbol.');
        }
      } catch (error) {
        console.error('Error fetching key statistics:', error);
        setError('Failed to fetch key statistics.');
      }
    };

    fetchStats();
  }, [company]);

  // Adjust handleSharesChange if needed
  const handleSharesChange = (e) => {
    const value = e.target.value;
    // Ensure shares is a positive integer
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      setShares(value);
    }
  };

  const estimatedCost = shares
    ? (shares * parseFloat(marketPrice)).toFixed(2)
    : '0.00';

  // Fetch user credentials and accounts
  useEffect(() => {
    const supabaseUserId = localStorage.getItem('supabaseUserId');
    console.log('Supabase User ID retrieved from local storage:', supabaseUserId);

    if (!supabaseUserId) {
      setError('Supabase User ID is missing.');
      setLoading(false);
      return;
    }

    const fetchUserCredentials = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${backendUrl}/post-authorization`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Supabase-User-ID': supabaseUserId,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch user credentials: ${errorText}`);
        }

        const data = await response.json();

        if (!data.accountsLinked) {
          // Redirect to linking flow or show a prompt
          alert('Please complete linking your brokerage account.');
          navigate('/link-snaptrade'); // Adjust the route as necessary
        } else {
          setUserId(data.userId);
          setUserSecret(data.userSecret);
          console.log('Fetched user credentials:', data);
        }
      } catch (err) {
        console.error('Error fetching user credentials:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCredentials();
  }, []);


  useEffect(() => {
    if (!userId || !userSecret) return;

    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${backendUrl}/accounts?userId=${userId}&userSecret=${userSecret}`
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch accounts: ${errorText}`);
        }

        const data = await response.json();
        console.log('Accounts fetched from backend:', data);
        setAccounts(
          data.filter((account) => !account.meta?.type.includes('crypto'))
        );
      } catch (err) {
        console.error('Error fetching accounts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [userId, userSecret]);

  const handleSelectAccount = async (accountId) => {
    setDisableInteractions(true);
    setIsLoading(true);
    setSelectedAccount(accountId);
    try {
      const symbolResponse = await fetch(
        `${backendUrl}/find-symbol?userId=${userId}&userSecret=${userSecret}&ticker=${company.ticker_symbol_ca}&exchange=TSXV&accountId=${accountId}`
      );
      if (!symbolResponse.ok) throw new Error('Failed to fetch symbol');
      const symbolData = await symbolResponse.json();
  
      // Store symbolData and universalSymbolId in state
      setSymbolData(symbolData);
      setUniversalSymbolId(symbolData.id);
  
      setCompanyName(symbolData.description);
  
      // Use symbolData.id directly here
      const stockResponse = await fetch(
        `${backendUrl}/stock-price?userId=${userId}&userSecret=${userSecret}&accountId=${accountId}&universalSymbolId=${symbolData.id}`
      );
      if (!stockResponse.ok) throw new Error('Failed to fetch stock price');
      const stockData = await stockResponse.json();
      setMarketPrice(
        stockData[0]?.last_trade_price
          ? parseFloat(stockData[0].last_trade_price).toFixed(2)
          : 'N/A'
      );
    } catch (err) {
      console.error('Error fetching symbol or stock price:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
      setDisableInteractions(false);
    }
  };
  

  const handleBuyStock = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/buy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          userSecret,
          accountId: selectedAccount,
          universalSymbolId: universalSymbolId, // Use universalSymbolId here
          quantity: parseInt(shares),
          orderType: 'Market',
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to place order: ${errorText}`);
      }
  
      const data = await response.json();
      console.log('Order response:', data);

      // Get the selected account's name for the confirmation
      const account = accounts.find((acc) => acc.id === selectedAccount);
      const accountName = account ? account.name : 'Unknown Account';

      // Prepare order details
      const confirmedOrderData = {
        symbol: company.ticker_symbol_ca,
        companyName: companyName || company.name,
        orderType: 'Market',
        quantity: shares,
        marketPrice: marketPrice,
        estimatedCost: estimatedCost,
        accountName: accountName,
        orderId: data.id || 'N/A',
        orderStatus: data.status || 'Pending',
      };

      // Update state to show confirmation
      setOrderData(confirmedOrderData);
      setOrderConfirmed(true);
    } catch (err) {
      console.error('Error placing order:', err);
      setError(err.message);
      alert(`Error placing order: ${err.message}`);
    } finally {
      setIsLoading(false);
      setDisableInteractions(false);
    }
  };

  // Function to open the video modal
  const openVideoModal = (video) => {
    setCurrentVideo(video);
    setShowModal('video');
  };

  // Function to open the news modal
  const openNewsModal = (newsItem) => {
    setCurrentNews(newsItem);
    setShowModal('news');
  };

  // Function to close the modal
  const closeModal = () => {
    setCurrentVideo(null);
    setCurrentNews(null);
    setShowModal(false);
  };

  // Handle loading and error states
  if (error) {
    return (
      <div className="container p-6 mx-auto">
        <div className="p-4 mb-4 text-red-700 bg-red-100 rounded-md">
          Error: {error}
        </div>
      </div>
    );
  }

  if (loading || accounts === null || !company) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <NavBar />

      {/* Display the loading overlay when isLoading is true */}
      {isLoading && <LoadingOverlay />}

      <div className="container p-6 mx-auto">
        {/* Display error message if any */}
        {error && (
          <div className="p-4 mb-4 text-red-700 bg-red-100 rounded-md">
            {error}
          </div>
        )}

        {/* Main Content and Buy Card Grid */}
        <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-3">
          {/* Main Content */}
          <div className="md:col-span-2">
            {/* Chart */}
            <div className="my-6">
              <div className="rounded-md h-96">
                <SymbolOverview
                  widgetProps={{
                    colorTheme: 'light',
                    symbols: [[`${tradingViewSymbol}|1Y`]],
                    showVolume: true,
                    backgroundColor: 'rgba(19, 23, 34, 0)',
                    widgetFontColor: 'rgba(19, 23, 43, 1)',
                  }}
                />
              </div>
            </div>

            {/* About Company */}
            <div>
              <h2 className="mb-2 text-lg font-semibold">
                About {company.name}
              </h2>
              <p className="mb-4 text-gray-700">{company.description}</p>

              {/* Company Key Statistics */}
              <h3 className="mb-2 text-lg font-semibold">
                {company.ticker_symbol_ca} Key Statistics
              </h3>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                {stats.length > 0 ? (
                  stats.map((stat, index) => (
                    <div key={index} className="text-left">
                      <p className="font-bold">{stat.title}</p>
                      <p className="text-gray-700">{stat.value}</p>
                    </div>
                  ))
                ) : (
                  <p>Loading key statistics...</p>
                )}
              </div>
              {/* Buy Card (Mobile) */}
              <div className="block md:hidden">
                <BuyCardPostAuth
                  company={company}
                  shares={shares}
                  handleSharesChange={handleSharesChange}
                  marketPrice={marketPrice}
                  estimatedCost={estimatedCost}
                  disableInteractions={disableInteractions}
                  accounts={accounts}
                  selectedAccount={selectedAccount}
                  handleSelectAccount={handleSelectAccount}
                  handleBuyStock={handleBuyStock}
                  orderConfirmed={orderConfirmed}
                  orderData={orderData}
                  setOrderConfirmed={setOrderConfirmed}
                  setOrderData={setOrderData}
                  setShares={setShares}
                  setSelectedAccount={setSelectedAccount}
                  setCompanyName={setCompanyName}
                  setMarketPrice={setMarketPrice}
                />
              </div>

              {/* Company Presentation */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">
                  Company Presentation
                </h3>
                <div className="mt-4">
                  <iframe
                    src={company.presentation}
                    title="Company Presentation"
                    width="100%"
                    height="600px"
                    allowFullScreen
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>

              {/* Company Media */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">
                  {company.ticker_symbol_ca} Media
                </h3>
                <div className="flex flex-wrap gap-4 mt-4">
                  {company.videos && company.videos.length > 0 ? (
                    company.videos.map((video, i) => (
                      <div
                        key={i}
                        className="relative w-full md:w-[48%] h-64 bg-gray-200 rounded-md cursor-pointer overflow-hidden"
                        onClick={() => openVideoModal(video)}
                      >
                        <img
                          src={video.thumbnail_url}
                          alt={video.title}
                          className="object-cover w-full h-full"
                        />
                        {/* Dark overlay */}
                        <div className="absolute inset-0 bg-black opacity-25"></div>
                        {/* Play Icon */}
                        <div className="absolute inset-0 flex items-center justify-center">
                          <svg
                            className="w-16 h-16 text-white opacity-75"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M6 4l12 6-12 6V4z" />
                          </svg>
                        </div>
                        {/* Video Title */}
                        <div className="absolute bottom-0 w-full p-2 text-sm text-white bg-black bg-opacity-50">
                          {video.title}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No videos available.</p>
                  )}
                </div>
              </div>

              {/* Buy Card (Mobile) */}
              <div className="block md:hidden">
                <BuyCardPostAuth
                  company={company}
                  shares={shares}
                  handleSharesChange={handleSharesChange}
                  marketPrice={marketPrice}
                  estimatedCost={estimatedCost}
                  disableInteractions={disableInteractions}
                  accounts={accounts}
                  selectedAccount={selectedAccount}
                  handleSelectAccount={handleSelectAccount}
                  handleBuyStock={handleBuyStock}
                  orderConfirmed={orderConfirmed}
                  orderData={orderData}
                  setOrderConfirmed={setOrderConfirmed}
                  setOrderData={setOrderData}
                  setShares={setShares}
                  setSelectedAccount={setSelectedAccount}
                  setCompanyName={setCompanyName}
                  setMarketPrice={setMarketPrice}
                />
              </div>


              {/* Company News */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">
                  {company.ticker_symbol_ca} News
                </h3>
                <ul className="mt-4 space-y-4 text-gray-700">
                  {company.news_releases && company.news_releases.length > 0 ? (
                    company.news_releases.map((newsItem, i) => (
                      <li
                        key={i}
                        className="cursor-pointer"
                        onClick={() => openNewsModal(newsItem)}
                      >
                        <p>{newsItem.pub_date}</p>
                        <p className="font-semibold">{newsItem.title}</p>
                      </li>
                    ))
                  ) : (
                    <p>No news available.</p>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {/* Buy Card (Sidebar for md and up) */}
          <div className="sticky self-start hidden md:col-span-1 top-20 md:block">
            <BuyCardPostAuth
              company={company}
              shares={shares}
              handleSharesChange={handleSharesChange}
              marketPrice={marketPrice}
              estimatedCost={estimatedCost}
              disableInteractions={disableInteractions}
              accounts={accounts}
              selectedAccount={selectedAccount}
              handleSelectAccount={handleSelectAccount}
              handleBuyStock={handleBuyStock}
              orderConfirmed={orderConfirmed}
              orderData={orderData}
              setOrderConfirmed={setOrderConfirmed}
              setOrderData={setOrderData}
              setShares={setShares}
              setSelectedAccount={setSelectedAccount}
              setCompanyName={setCompanyName}
              setMarketPrice={setMarketPrice}
            />
          </div>
        </div>

        {/* Video Modal */}
        {showModal === 'video' && currentVideo && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-5xl p-4 bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">
                  {currentVideo.title}
                </h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div className="relative" style={{ paddingTop: '56.25%' }}>
                <iframe
                  src={`${currentVideo.url}?autoplay=1`}
                  title={currentVideo.title}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        {/* News Modal */}
        {showModal === 'news' && currentNews && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-3xl max-h-full p-4 overflow-y-auto bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">
                  {currentNews.title}
                </h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div>
                <p>{currentNews.pub_date}</p>
                <div className="mt-4">
                  <iframe
                    src={currentNews.url}
                    title={currentNews.title}
                    width="100%"
                    height="600px"
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PostAuthorization;
