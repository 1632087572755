// BuyCardPostAuth.js

import React from 'react';

const BuyCardPostAuth = ({
  company,
  shares,
  handleSharesChange,
  marketPrice,
  estimatedCost,
  disableInteractions,
  accounts,
  selectedAccount,
  handleSelectAccount,
  handleBuyStock,
  orderConfirmed,
  orderData,
  setOrderConfirmed,
  setOrderData,
  setShares,
  setSelectedAccount,
  setCompanyName,
  setMarketPrice,
}) => {
  return (
    <div className="p-4 border-blue-500 rounded-md shadow-md">
      {!orderConfirmed ? (
        <>
          <h2 className="mb-4 text-lg font-semibold">
            Buy {company.ticker_symbol_ca}
          </h2>
          <div className="flex items-center justify-between mb-4">
            <label className="font-bold">Shares</label>
            <input
              type="number"
              value={shares}
              onChange={handleSharesChange}
              placeholder="Shares"
              className="w-24 p-2 border rounded-md"
              min="1"
              disabled={disableInteractions}
            />
          </div>
          <div className="flex items-center justify-between mb-2">
            <p className="font-bold">Market Price</p>
            <p>${marketPrice}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="font-bold">Estimated Cost</p>
            <p>${estimatedCost}</p>
          </div>

          {/* Account Selection */}
          <div className="mt-4">
            <label className="font-bold">Select Account</label>
            <select
              value={selectedAccount || ''}
              onChange={(e) => handleSelectAccount(e.target.value)}
              className="w-full p-2 mt-2 border rounded-md"
              disabled={disableInteractions}
            >
              <option value="" disabled>
                -- Select an Account --
              </option>
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name.replace('Wealthsimple Trade', '')} - $
                  {account.balance !== null && account.balance !== undefined
                    ? account.balance.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 'N/A'}
                </option>
              ))}
            </select>
          </div>

          <button
            className={`w-full py-2 mt-4 text-white bg-blue-500 rounded-md ${
              !selectedAccount || !shares || disableInteractions
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
            onClick={handleBuyStock}
            disabled={!selectedAccount || !shares || disableInteractions}
          >
            Buy
          </button>
        </>
      ) : (
        <div className="p-6 bg-white rounded-lg">
          <div className="flex items-center justify-center mb-4">
            {/* Success Icon */}
            <svg
              className="w-12 h-12 text-green-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <h3 className="mb-2 text-xl font-semibold text-center">
            Order Confirmed
          </h3>
          <p className="mb-6 text-center text-gray-600">
            Your order has been placed successfully.
          </p>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Account:</span>
              <span className="text-gray-900">
                {orderData?.accountName || 'N/A'}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Symbol:</span>
              <span className="text-gray-900">
                {orderData?.symbol || 'N/A'} ({orderData?.companyName || 'N/A'})
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Order Type:</span>
              <span className="text-gray-900">
                {orderData?.orderType || 'N/A'}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Quantity:</span>
              <span className="text-gray-900">
                {orderData?.quantity || 'N/A'}
              </span>
            </div>
            {orderData?.orderType === 'Limit' && (
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">Limit Price:</span>
                <span className="text-gray-900">
                  ${orderData.limitPrice || 'N/A'}
                </span>
              </div>
            )}
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Market Price:</span>
              <span className="text-gray-900">
                ${orderData?.marketPrice || 'N/A'}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Estimated Cost:</span>
              <span className="text-gray-900">
                ${orderData?.estimatedCost || 'N/A'}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Order ID:</span>
              <span className="text-gray-900">
                {orderData?.orderId || 'N/A'}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Status:</span>
              <span className="text-gray-900">
                {orderData?.orderStatus || 'N/A'}
              </span>
            </div>
          </div>
          <button
            onClick={() => {
              // Reset the form for a new order
              setOrderConfirmed(false);
              setOrderData(null);
              setShares('');
              setSelectedAccount(null);
              setCompanyName('');
              setMarketPrice('0.00');
            }}
            className="w-full py-2 mt-6 text-white transition duration-200 bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Place Another Order
          </button>
        </div>
      )}
    </div>
  );
};

export default BuyCardPostAuth;
