// src/components/LinkAccountButton.js
import React, { useState } from 'react';
import posthog from 'posthog-js';

const LinkAccountButton = ({ supabaseUserId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleLinkTradingAccount = async () => {
    posthog.capture('button_clicked', {
      buttonName: 'Link Account Button',
      location: 'Enter Giveaway Page',
    });
    setLoading(true);
    setError(null);
    try {
      if (!supabaseUserId) {
        throw new Error('Supabase User ID is missing. Please try logging in again.');
      }

      // Store Supabase user ID in localStorage
      localStorage.setItem('supabaseUserId', supabaseUserId);

      // Send request to link trading account, passing the Supabase user ID in headers
      const response = await fetch(`${backendUrl}/link-snaptrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId, // Send Supabase User ID in headers
        },
        body: JSON.stringify({
          snaptradeUserId: `snaptrade-${supabaseUserId}`, // SnapTrade User ID format
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Redirecting to:', data.redirectURI);
        window.location.href = data.redirectURI; // Redirect to SnapTrade
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to link trading account');
      }
    } catch (error) {
      console.error('Error during linking:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading user information...</p>;
  }

  return (
    <div className="mt-4 text-left">
      <p
        className="text-gray-300 mb-2 font-sans"
        style={{ fontFamily: 'Arial, sans-serif', fontWeight: '400', fontSize: '18px' }}>
        <span className="font-bold text-white uppercase">
          Get 100 more entries when you link your trading account for free!
        </span> 
      </p>
      <button
        onClick={handleLinkTradingAccount}
        className="bg-white font-bold uppercase text-black py-3 px-6 rounded-lg transition duration-300 border border-black hover:bg-black hover:text-white hover:border-white"
        disabled={loading || !supabaseUserId}
      >
        {loading ? 'Linking...' : 'Link Account'}
      </button>
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
};

export default LinkAccountButton;
